import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import Dashboard from './Dashboard';

const DefaultContain = () => {
  const val = true;
  return (
    <Fragment>
        <Row>
          <Dashboard />
        </Row>
    </Fragment>
  );
};
export default DefaultContain;
