import React, { Fragment, useState, useCallback, useEffect } from 'react';
import {
  Card, CardBody, Form, FormGroup, Input, Label, Row, Col, Button, Nav,
  NavItem, NavLink, TabContent, TabPane, FormFeedback, Alert, CardFooter
} from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { SaveRepaymentData, HeaderApi, GetAllCollections, GetCollection, VerifyPaymnet, ViewDocuments } from '../../api/index';
import { fetchCollectionDetailsData } from '../Task/Tasks';
import { formatDateYYYYMMDD, formatDate, formatDateTime, arraysHaveAnyCommonElements } from '../../utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LoanDetailsComponent = ({ lead_id }) => {
  const [activeTab, setActiveTab] = useState('repaymentLog');
  const [data, setData] = useState({});
  const paymentModes = useSelector(state => state.masters.paymentModeTypesMaster);
  const [repaymentData, setRepaymentData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const userID = localStorage.getItem('user_id');
  const [formData, setFormData] = useState({
    received_amount: 0,
    reference_number: '',
    payment_status: '',
    payment_mode: '',
    discount_amount: '0',
    extra_amount: '0',
    payment_date: new Date().toISOString().split('T')[0],
    screenshot: null,
    remarks: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitMessage, setSubmitMessage] = useState(null);
  const navigate = useNavigate();

  const headerStyle = {
    backgroundColor: '#0074bd',
    fontWeight: 'bold',
    color: "#fff"
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleFileChange = (e) => {
    setFormData({ ...formData, screenshot: e.target.files[0] });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "received_amount") {
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setFormErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const bankVerificationList = [
    { ID: 27, name: 'PART-PAYMENT' },
    { ID: 24, name: 'CLOSED' },
    { ID: 25, name: 'SETTLED' },
    { ID: 26, name: 'WRITE-OFF' }
  ];

  useEffect(async () => {
    try {
      const collectionData = await fetchCollectionDetailsData(lead_id);
      setData(collectionData.data || {});
      fetchAllRepaymentData();
    } catch (error) {
      console.error('Error while fetching collection details:', error);
    }

  }, [lead_id, editMode]);

  const renderInput = useCallback((key, value) => {
    switch (key) {
      case "payment_status":
        return (
          <Input id="payment_status" name="payment_status" type="select" value={formData.payment_status} invalid={!!formErrors['payment_status']} onChange={handleInputChange}>
            <option value="">Select Payment Status</option>
            {bankVerificationList.map((status, index) => (<option key={index} value={status.ID}>{status.name}</option>))}
          </Input>
        );
      case "remarks":
        return (
          <textarea
            id={key}
            name={key}
            value={formData.remarks}
            className={`form-control ${formErrors['remarks'] ? 'is-invalid' : ''}`}
            onChange={handleInputChange}
            rows="2"
          ></textarea>
        );
      case "screenshot":
        return (
          <Input id="screenshot" name="screenshot" type="file" invalid={!!formErrors['screenshot']} onChange={handleFileChange} />
        );
      case "payment_mode":
        return (
          <Input id="payment_mode" name="payment_mode" type="select" value={formData.payment_mode} invalid={!!formErrors['payment_mode']} onChange={handleInputChange}>
            <option value="">Select Payment Mode</option>
            {paymentModes.map((mode, index) => (<option key={index} value={mode.ID}>{mode.name}</option>))}
          </Input>
        );
      case "payment_date":
        return (
          <Input
            id={key}
            name={key}
            type="date"
            value={formData[key] || ''}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
      case "payment_amount":
        return (
          <Input
            id={key}
            name={key}
            type="number"
            value={formData[key] || ''}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
      case "recovery_id":
        return (
          <Input
            id={key}
            name={key}
            type="hidden"
            value={formData[key] || ''}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
      default:
        return (
          <Input
            id={key}
            name={key}
            value={formData[key] || ''}
            onChange={handleInputChange}
            invalid={!!formErrors[key]}
          />
        );
    }
  }, [formData, formErrors, handleInputChange]);

  const renderFormGroups = (
    <Row>
      {Object.entries(formData).map(([key, value], index) => (
        <Col md={6} key={index} style={{ display: (key == "recovery_id" ? "none" : "") }}>
          <FormGroup>
            <Label for={key}>
              {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </Label>
            {renderInput(key, value)}
            {formErrors[key] && <FormFeedback>{formErrors[key]}</FormFeedback>}
          </FormGroup>
        </Col>
      ))}
    </Row>
  );

  const validateForm = () => {
    const errors = {};

    if (!formData.received_amount) {
      errors.received_amount = "Payment amount is required";
    } else if (isNaN(formData.received_amount) || Number(formData.received_amount) <= 0) {
      errors.received_amount = "Payment amount must be a valid number greater than zero";
    }

    if (!formData.received_amount) errors.received_amount = "Payment amount is required";
    if (!formData.reference_number) errors.reference_number = "Reference is required";
    if (!formData.payment_status) errors.payment_status = "Payment status is required";
    if (!formData.payment_mode) errors.payment_mode = "Payment mode is required";
    if (!formData.payment_date) errors.payment_date = "Payment date is required";
    if (!formData.remarks) errors.remarks = "Remarks type is required";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      // Here you would typically send the data to your backend

      const requestData = new FormData();
      requestData.append('lead_id', lead_id);
      requestData.append('received_amount', formData.received_amount);
      requestData.append('reference_number', formData.reference_number);
      requestData.append('payment_status', formData.payment_status);
      requestData.append('payment_mode', formData.payment_mode);
      requestData.append('discount_amount', formData.discount_amount);
      requestData.append('extra_amount', formData.extra_amount);
      requestData.append('payment_date', formData.payment_date);
      requestData.append('screenshot', formData.screenshot);
      requestData.append('remarks', formData.remarks);

      const response = await axios.put(`${SaveRepaymentData}`, requestData, { headers: HeaderApi });
      toast.success('Repayment uploaded successfully');

      setFormData({
        payment_status: '',
        payment_amount: '',
        payment_date: '',
        remarks: '',
        payment_mode: '',
        reference_number: '',
        screenshot: null
      });

      setSubmitMessage({ type: 'success', text: 'Repayment uploaded successfully!' });
      fetchAllRepaymentData();
    } else {
      setFormErrors(errors);
      setSubmitMessage({ type: 'danger', text: 'Please fill all the required fields!' });
    }
  };

  const handleView = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${ViewDocuments}${parseInt(id, 10)}/2`, {
        headers: HeaderApi,
        responseType: 'blob'
      });
      const contentType = response.headers['content-type'];

      const file = new Blob(
        [response.data],
        { type: contentType });

      const fileURL = URL.createObjectURL(file);

      const newWindow = window.open(fileURL, '_blank');

      if (!newWindow) {
        toast.error('A popup blocker may be preventing the document from opening.');
      } else {
        setTimeout(() => newWindow.close(), 300000);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      toast.error('Document file does not exist');
    }
  };

  const handleDownload = async (e, id) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${ViewDocuments}${parseInt(id, 10)}/2`, {
        headers: HeaderApi,
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/octet-stream' }); // the type is set to PDF

      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      // Open the URL on new Window
      const newWindow = window.open(fileURL, '_blank');

      if (!newWindow) {
        toast.error('A popup blocker may be preventing the document from opening.');
      } else {
        setTimeout(() => newWindow.close(), 10000);
      }

    } catch (error) {
      console.error('Error fetching document:', error);
      toast.error('Document file does not exist');
    }
  };

  const fetchAllRepaymentData = async () => {
    const apiUrl = `${GetAllCollections}${lead_id}`;
    try {
      const response = await axios.get(apiUrl, { headers: HeaderApi });
      setRepaymentData(response.data.data);
    } catch (error) {
      setRepaymentData([]);
      console.error('Error fetching state list:', error);
      toast.error(error.response?.data?.error || 'Error occurred', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      });
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  const handleEditVerifyPayment = async (e, id) => {
    e.preventDefault();
    setEditMode(true);
    try {
      const response = await axios.get(`${GetCollection}${id}`, {
        headers: HeaderApi
      });
      const paymentData = response.data.data;

      setFormData({
        recovery_id: paymentData.ID,
        received_amount: paymentData.received_amount,
        reference_number: paymentData.reference_number,
        payment_status: paymentData.repayment_status_id,
        payment_mode: paymentData.payment_mode_id,
        discount_amount: paymentData.discount || '0',
        extra_amount: paymentData.refund || '0',
        payment_date: formatDateYYYYMMDD(paymentData.date_of_received),
        remarks: paymentData.remarks
      });
    } catch (error) {
      console.error('Error fetching payment details:', error);
      toast.error('Failed to fetch payment details');
    }
  };

  const handleVerifyPayment = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      const requestData = {
        lead_id: parseInt(lead_id),
        recovery_id: formData.recovery_id,
        received_amount: parseFloat(formData.received_amount),
        reference_number: formData.reference_number,
        payment_mode_id: parseInt(formData.payment_mode),
        repayment_status_id: parseInt(formData.payment_status),
        payment_verification: 1,
        date_of_received: formatDateYYYYMMDD(formData.payment_date),
        closure_remarks: formData.remarks,
        discount: parseFloat(formData.discount_amount),
        refund: parseFloat(formData.extra_amount),
        user_id: parseInt(userID)
      };

      try {
        const isConfirmed = window.confirm("Are you sure you want to verify this payment?");
        if (isConfirmed) {
          const response = await axios.post(`${VerifyPaymnet}`, requestData, { headers: HeaderApi });
          toast.success('Repayment verified successfully');

          setFormData({
            recovery_id: '',
            received_amount: '',
            reference_number: '',
            payment_status: '',
            payment_mode: '',
            discount_amount: '',
            extra_amount: '',
            payment_date: '',
            remarks: ''
          });

          setSubmitMessage({ type: 'success', text: 'Repayment verified successfully!' });
          navigate(`${process.env.PUBLIC_URL}/lead-details/${lead_id}/23`);
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        toast.error('Failed to verify payment. Please try again.');
        setSubmitMessage({ type: 'danger', text: 'Failed to verify payment. Please try again.' });
      }
    } else {
      setFormErrors(errors);
      setSubmitMessage({ type: 'danger', text: 'Please fill all the required fields!' });
    }
  };

  return (
    <Fragment>
      <div className="edit-profile" style={{ marginTop: 10 }}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <table className="table table-bordered table-hover table-striped">
                    <tbody>
                      <tr>
                        <th>Loan No.</th>
                        <td>{data.loan_no}</td>
                        <th>Status</th>
                        <td>{data.status}</td>
                      </tr>
                      <tr>
                        <th>Loan Amount (Rs.)</th>
                        <td>{data.loan_recommended}</td>
                        <th>Tenure (Days)</th>
                        <td>{data?.tenure || 0}</td>
                      </tr>
                      <tr>
                        <th>ROI (%)</th>
                        <td>{data.roi}</td>
                        <th>Interest (Rs.)</th>
                        <td>{(data.repayment_amount - data.loan_recommended)}</td>
                      </tr>
                      <tr>
                        <th>Disbursal Date</th>
                        <td>{formatDate(data.disbursal_date)}</td>
                        <th>Repay Amount (Rs.)</th>
                        <td>{data.repayment_amount}</td>
                      </tr>
                      <tr>
                        <th>Repay Date</th>
                        <td>{formatDate(data.repayment_date)}</td>
                        <th>Delay (Days)</th>
                        <td>{data?.penal_tenure || 0}</td>
                      </tr>
                      <tr>
                        <th>Tenure till today (Days)</th>
                        <td>{data?.real_tenure || 0}</td>
                        <th>Default ROI% (Per Day)</th>
                        <td>{(parseFloat(data.roi) * 2)}</td>
                      </tr>
                      <tr>
                        <th>Repay Amount till today (Rs.)</th>
                        <td>{data?.repayment_with_real_interest || 0}</td>
                        <th>Default Interest (Rs.)</th>
                        <td>{data?.penalty_payable_amount}</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="table table-bordered table-hover table-striped" style={{ marginTop: '20px' }}>
                    <thead>
                      <tr>
                        <th style={headerStyle}>Description</th>
                        <th style={headerStyle}>Payable Amount</th>
                        <th style={headerStyle}>Received Amount</th>
                        <th style={headerStyle}>Waiver Amount</th>
                        <th style={headerStyle}>Outstanding Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Principal</td>
                        <td>{data?.principle_payable_amount || '0.00'}</td>
                        <td>{data?.principle_received_amount || '0.00'}</td>
                        <td>{data?.principle_discount_amount || '0.00'}</td>
                        <td>{data.principle_outstanding_amount}</td>
                      </tr>
                      <tr>
                        <td>Normal Interest</td>
                        <td>{data?.interest_payable_amount || '0.00'}</td>
                        <td>{data?.interest_received_amount || '0.00'}</td>
                        <td>{data?.interest_discount_amount || '0.00'}</td>
                        <td>{data?.interest_outstanding_amount || '0.00'}</td>
                      </tr>
                      <tr>
                        <td>Penal Interest</td>
                        <td>{data?.penalty_payable_amount || '0.00'}</td>
                        <td>{data?.penalty_received_amount || '0.00'}</td>
                        <td>{data?.penalty_discount_amount || '0.00'}</td>
                        <td>{data?.penalty_outstanding_amount || '0.00'}</td>
                      </tr>
                      <tr>
                        <td>Grand Total</td>
                        <td>{data?.total_payable_amount || '0.00'}</td>
                        <td>{data?.total_received_amount || '0.00'}</td>
                        <td>{data?.total_discount_amount || '0.00'}</td>
                        <td>{data?.total_outstanding_amount || '0.00'}</td>
                      </tr>
                    </tbody>
                  </table>
                  &nbsp;
                  <Nav tabs className="mt-4 mb-4" >
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={activeTab === 'repaymentLog' ? 'active' : ''}
                        onClick={() => toggleTab('repaymentLog')}
                      >
                        Repayment Details
                      </NavLink>
                    </NavItem>
                    &nbsp;&nbsp;&nbsp;
                    {["23", "27"].includes(data?.lead_status_id) && (
                      <NavItem style={{ cursor: "pointer" }}>
                        <NavLink
                          className={activeTab === 'addRepayment' ? 'active' : ''}
                          onClick={() => toggleTab('addRepayment')}
                        >
                          Add Repayment
                        </NavLink>
                      </NavItem>
                    ) || null}
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="repaymentLog">
                      {!editMode && (
                        <Row className="table-responsive">
                          <table className="table table-striped table-bordered table-hover" style={{ border: "1px solid #dde2eb" }}>
                            <thead>
                              <tr>
                                <th style={headerStyle}><b>ID</b></th>
                                <th style={headerStyle}><b>&nbsp;&nbsp;&nbsp;Action&nbsp;&nbsp;&nbsp;</b></th>
                                <th style={headerStyle}><b>Remarks</b></th>
                                <th style={headerStyle}><b>Payment&nbsp;Mode</b></th>
                                <th style={headerStyle}><b>Payment&nbsp;Amount</b></th>
                                <th style={headerStyle}><b>Discount</b></th>
                                <th style={headerStyle}><b>Refund</b></th>
                                <th style={headerStyle}><b>Reference&nbsp;No</b></th>
                                <th style={headerStyle}><b>Recovery&nbsp;Date</b></th>
                                <th style={headerStyle}><b>Payment&nbsp;Status</b></th>
                                <th style={headerStyle}><b>Status</b></th>
                                <th style={headerStyle}><b>Payment&nbsp;Uploaded&nbsp;By</b></th>
                                <th style={headerStyle}><b>Payment&nbsp;Uploaded&nbsp;on</b></th>
                                <th style={headerStyle}><b>Payment&nbsp;Verified&nbsp;By</b></th>
                                <th style={headerStyle}><b>Payment&nbsp;Verified&nbsp;On</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              {repaymentData.length > 0 ? repaymentData.map((repayment, index) => (

                                <tr key={index}>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.ID}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff", textAlign: "center" }}>
                                    {(repayment.payment_verification == 0 && ["23", "27"].includes(data?.lead_status_id)) && (
                                      <a onClick={(e) => handleEditVerifyPayment(e, repayment.ID)}>
                                        <i className="fa fa-edit" style={{ fontSize: "17px", color: "#0074bd", cursor: 'pointer' }}></i>
                                      </a>) || null
                                    }

                                    <a onClick={(e) => handleView(e, repayment.ID)}>
                                      <i className="fa fa-eye" style={{ padding: "3px", color: "#35b7c4", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i>
                                    </a>

                                    <a onClick={(e) => handleDownload(e, repayment.ID)}>
                                      <i className="fa fa-download" style={{ padding: "3px", color: "#00b01c", border: "1px", solid: "#35b7c4", cursor: 'pointer' }}></i>
                                    </a>
                                  </td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.remarks}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.payment_mode?.name || '-'}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.received_amount}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.discount}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.refund}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.reference_number}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{formatDate(repayment.date_of_received) || '-'}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{(repayment.payment_verification == 1 ? "Approved" : "Pending")}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{(repayment.repayment_status?.status_name || "-")}</td>

                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.collection_executive_user?.name}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{(repayment.collection_executive_payment_created_on != "0001-01-01T00:00:00Z" ? formatDateTime(repayment.collection_executive_payment_created_on) : "-")}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{repayment.closure_user?.name}</td>
                                  <td style={{ backgroundColor: repayment.payment_verification == 2 ? '#ffd7d7' : "#fff" }}>{(repayment.closure_payment_updated_on != "0001-01-01T00:00:00Z" ? formatDateTime(repayment.closure_payment_updated_on) : "-")}</td>
                                </tr>
                              )) : (
                                <tr>
                                  <td colSpan="16" style={{ textAlign: "center", color: "red" }}>Record Not Found...</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </Row>

                      ) || (
                          <Form onSubmit={handleVerifyPayment}>
                            {renderFormGroups}
                            <CardFooter className="text-center">
                              <Button type="submit" color="btn btn-outline-success">VERIFY REPAYMENT</Button>
                              <Button onClick={() => setEditMode(false)} color="btn btn-outline-danger" style={{ marginLeft: 10 }}>CANCEL</Button>
                            </CardFooter>
                          </Form>

                        )}
                    </TabPane>
                    {["23", "27"].includes(data?.lead_status_id) && (
                      <TabPane tabId="addRepayment">
                        {submitMessage && (
                          <Alert color={submitMessage.type} className="mt-3">
                            {submitMessage.text}
                          </Alert>
                        )}
                        <Form onSubmit={handleSubmit}>
                          {renderFormGroups}
                          <Button type="submit" color="btn btn-outline-success">RECEIVE REPAYMENT</Button>
                        </Form>
                      </TabPane>
                    ) || null}
                  </TabContent>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default LoanDetailsComponent;
