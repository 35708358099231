import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Row } from 'reactstrap';
import HeaderContain from './HeaderContain';
import SearchBar from './SearchBar';
import CheckContext from '../../_helper/customizer/index';

const Header = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

  }
  const width = useWindowSize();

  useEffect(() => {
    toggleSidebar(true);
  }, []);

  return (
    <Fragment>
      <Row className="header-wrapper m-0">
        <SearchBar />
        <HeaderContain />
      </Row>
    </Fragment>
  );
};
export default Header;
