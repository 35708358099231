import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import LoginForm from '.';
import imgg from '../assets/images/login/2.jpg';

const Logins = () => {
    var isLoggedIn = localStorage.getItem('authenticated');
    if (isLoggedIn) {
        localStorage.setItem('authenticated', true);
        window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <div className="login-card1" style={{ backgroundColor: "#013838" }}>
                        <LoginForm />
                    </div>
                </Row>
            </Container>
        </Fragment >
    );
};

export default Logins;
