import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MenuListApi, HeaderApi } from '../../api/index';

// Add all Font Awesome solid icons to the library
library.add(fas);

const DashboardItem = ({ icon, label, color, gradient, routeLink }) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`${process.env.PUBLIC_URL}/${routeLink}`);
    };

    return (
        <div
            style={{
                width: '150px',
                height: '150px',
                margin: '15px',
                position: 'relative',
                transition: 'all 0.3s ease',
                transform: isHovered ? 'translateY(-10px)' : 'none',
                cursor: 'pointer',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleClick}
        >
            <div style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                background: `linear-gradient(135deg, ${gradient})`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: isHovered ? '0 12px 20px rgba(0,0,0,0.2)' : '0 6px 12px rgba(0,0,0,0.1)',
                transition: 'all 0.3s ease',
            }}>
                <FontAwesomeIcon icon={icon} style={{ fontSize: '2.5rem', color: 'white', marginBottom: '10px' }} />
                <span style={{
                    color: 'white',
                    fontSize: '0.9rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    maxWidth: '80%',
                    textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                }}>
                    {label}
                </span>
            </div>
            {isHovered && (
                <div style={{
                    position: 'absolute',
                    top: '-10px',
                    right: '-10px',
                    background: 'white',
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                }}>
                    <FontAwesomeIcon icon="arrow-right" style={{ color: color, fontSize: '0.8rem' }} />
                </div>
            )}
        </div>
    );
};

const Dashboard = () => {
    const [menuData, setMenuData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(MenuListApi, { headers: HeaderApi });
                const data = response.data;
                if (data.status === 1) {
                    const transformedData = Object.values(data.data).map(item => {
                        const menuItem = Object.values(item)[0];
                        return {
                            icon: menuItem.icon.replace('fa fa-', ''),
                            label: menuItem.menu_sub_name,
                            color: menuItem.box_bg_color || '#4a4a4a',
                            gradient: menuItem.gradient_color || "#606c88, #3f4c6b",
                            routeLink: menuItem.route_link,
                        };
                    });
                    setMenuData(transformedData);
                }
            } catch (error) {
                console.error('Error fetching menu data:', error);
                if (error.response && error.response.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                }
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{
            backgroundColor: '#f4f6f8',
            minHeight: '100vh',
            padding: '40px 20px',
            fontFamily: 'Arial, sans-serif',
        }}>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                maxWidth: '1400px',
                margin: '0 auto',
            }}>
                {menuData.map((item, index) => (
                    <DashboardItem key={index} {...item} />
                ))}
            </div>
        </div>
    );
};

export default Dashboard;
