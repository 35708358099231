import React, { useState, useEffect, useCallback, useContext, useRef, Fragment } from 'react';
import { Col, Input } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import ListOfMenu from './Bookmark/ListOfMenu';
import EmptyClass from './Bookmark/EmptyClass';
import CustomContext from '../../_helper/customizer';
import { Search } from 'react-feather';
import { SearchIconss } from '../../Data/svgIcons';
import { toast } from 'react-toastify';
import { HeaderApi, MainSearch } from '../../api/index';
import '../../styles/Searchbar.css';

const filterOptions = [
    { value: 'select_all', label: 'Select All' },
    { value: 'id', label: 'Lead ID' },
    { value: 'loan_no', label: 'Loan Number' },
    { value: 'pancard', label: 'Pan Card' },
    { value: 'first_name', label: 'First Name' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'alternate_mobile', label: 'Alternate Mobile' },
    { value: 'email', label: 'Email' },
    { value: 'alternate_email', label: 'Alternate Email' }
];

const Searchbar = () => {
    const [searchValue, setSearchValue] = useState('');
    const [searchIcon, setSearchIcon] = useState(false);
    const [searchToggle, setSearchToggle] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [isOutputVisible, setIsOutputVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setIsClose } = useContext(CustomContext);
    const [isOpen, setIsOpen] = useState(false);
    // const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([
        { value: 'id', label: 'Lead ID' },
        { value: 'loan_no', label: 'Loan Number' },
        { value: 'pancard', label: 'Pan Card' },
        { value: 'mobile', label: 'Mobile' },
        { value: 'alternate_mobile', label: 'Alternate Mobile' },
        { value: 'email', label: 'Email' },
        { value: 'alternate_email', label: 'Alternate Email' },
    ]);
    const searchBarRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const isFetchingRef = useRef(false);
    const loadMoreTimerRef = useRef(null);

    const toggleSearchBar = () => setIsOpen(!isOpen);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            clearSearch();
        }
    }, []);

    const handleClickOutside = useCallback((event) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
            clearSearch();
        }
    }, []);

    const fetchSearchResults = useCallback(async (keyword, page) => {
        if (isFetchingRef.current) return;

        isFetchingRef.current = true;
        setLoading(true);

        try {
            const filters = selectedFilters
                .filter(filter => filter.value !== 'select_all')
                .map(filter => filter.value)
                .join(',');
            const fields = 'id,first_name,mobile,loan_no';

            const response = await axios.get(MainSearch, {
                params: {
                    q: keyword,
                    filters: filters,
                    fields: fields,
                    page: page,
                    limit: itemsPerPage
                },
                headers: HeaderApi
            });

            const items = response.data.items.map(item => ({
                ...item,
                type: 'link',
            }));

            setSearchResult(prevItems => (page === 1 ? items : [...prevItems, ...items]));
            setTotalItems(response.data.total);
            setCurrentPage(page);
            setSearchIcon(items.length === 0);

            // Automatically load more if there are more items
            if (page * itemsPerPage < response.data.total) {
                loadMoreTimerRef.current = setTimeout(() => {
                    handleSearchKeyword(keyword, page + 1);
                }, 5000); // 5-second delay for next pagination call
            }
            return response.data.total;
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchResult([]);
            setSearchIcon(true);
        } finally {
            setLoading(false);
            isFetchingRef.current = false;
        }
    }, [selectedFilters, itemsPerPage]);

    const handleSearchKeyword = useCallback(async (keyword, page = 1) => {
        setSearchValue(keyword);

        if (keyword && selectedFilters.length > 0) {
            setSearchToggle(true);
            setIsOutputVisible(true);

            await fetchSearchResults(keyword, page);
        } else {
            clearSearch();

            if (!selectedFilters.length) {
                toast.error('Please select a filter option.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000
                });
            }
        }
    }, [selectedFilters, itemsPerPage, fetchSearchResults]);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
            document.removeEventListener('click', handleClickOutside, false);
            if (loadMoreTimerRef.current) {
                clearTimeout(loadMoreTimerRef.current);
            }
        };
    }, [escFunction, handleClickOutside]);

    const clearSearch = () => {
        setSearchValue('');
        setSearchResult([]);
        setSearchIcon(false);
        setIsOutputVisible(false);
        setCurrentPage(1);
        setTotalItems(0);
        if (loadMoreTimerRef.current) {
            clearTimeout(loadMoreTimerRef.current);
        }
    };

    const handleFilterChange = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'select_all')) {
            if (selectedOptions.length === filterOptions.length) {
                setSelectedFilters(selectedOptions.filter(option => option.value !== 'select_all'));
            } else {
                setSelectedFilters(filterOptions.filter(option => option.value !== 'select_all'));
            }
        } else {
            setSelectedFilters(selectedOptions);
        }
    };

    const handleItemClick = () => {
        clearSearch();
    };

    return (
        <Fragment>
            <Col className="left-side-header ps-0 d-md-block dash-10">
                <div className="w-100 searchbar" ref={searchBarRef}>
                    <div className="resp-serch-box d-md-none resp-serch-input">
                        <Search onClick={toggleSearchBar} />
                    </div>

                    <div className={`input-group form-group search-form ${isOpen ? 'open' : ''}`}>
                        <span className='input-group-text search-icon'><SearchIconss /></span>
                        <Input
                            type="text"
                            placeholder="Search here.."
                            value={searchValue}
                            onChange={(e) => handleSearchKeyword(e.target.value, 1)}
                            className="search-input"
                        />

                        {/* <div className="fixed-filter">
                            <Select
                                isMulti
                                value={selectedFilters}
                                onChange={handleFilterChange}
                                options={filterOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select filters..."
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        height: '100%'
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: '100%',
                                        height: '100%'
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: '100%',
                                        overflowY: 'auto'
                                    })
                                }}
                            />
                        </div> */}

                        {isOutputVisible && (
                            <>
                                {loading ? (
                                    <div className="loading-indicator">
                                        <span>Loading...</span>
                                    </div>
                                ) : (
                                    <ListOfMenu
                                        searchValue={searchValue}
                                        searchResult={searchResult}
                                        searchBar={true}
                                        searchToggle={searchToggle}
                                        removeFix={clearSearch}
                                    />
                                )}
                            </>
                        )}
                        {isOutputVisible && <EmptyClass searchIcon={searchIcon} search={true} />}
                    </div>
                </div>
            </Col>
        </Fragment>
    );
};

export default Searchbar;
