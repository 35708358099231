import React, { Fragment } from 'react';
import DefaultContain from '../../../Component/DashBoard/index';

const Default = () => {
  return (
    <Fragment>
      <DefaultContain />
    </Fragment>
  );
};
export default Default;
