import React, { Fragment, useEffect, useState } from 'react';
import { FileText, LogIn, Mail, Settings, User } from 'react-feather';
import { Media } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { H6, Image, LI, UL } from '../../AbstractElements';
import UserImg from '../../assets/images/avtar/man.png';
import ItemCart from './ItemCart';
import MaxMiniSize from './MaxMiniSize';
import MoonLight from './MoonLight';
import Notification from './Notification';
import Language from './Langauge';
import Bookmark from './Bookmark/index';
import { Account, Inbox, LogOut, Taskboard } from '../../Constant';
import profilepic from '../../assets/images/user/user-dp.png';

const HeaderContain = () => {// eslint-disable-next-line
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || UserImg);
    setName(localStorage.getItem('name'));
  }, []);
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));
  const history = useNavigate();
  const Logout = () => {
    localStorage.clear();
    history(`${process.env.PUBLIC_URL}/login`);
  };
  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76">
        <UL attrUL={{ className: `simple-list flex-row nav-menus` }}>
          {/* <LI attrLI={{ className: 'onhover-dropdown' }}><H6 attrH6={{ className: 'txt-dark mb-0 mt-1' }}>EN</H6>
            <Language />
          </LI> */}
          {/* <MoonLight /> */}
          {/* <ItemCart />
          <Bookmark /> */}
          {/* <Notification /> */}
          <LI>
            <Link to="/dashboard"><i class="fa fa-home" style={{ fontSize: '30px', color: '#000000' }} aria-hidden="true"></i>
            </Link>
          </LI>
          <MaxMiniSize />
          <LI attrLI={{ className: 'profile-nav onhover-dropdown pe-0 pt-0 me-0' }} >
            <Media className="profile-media">
              <Image attrImage={{
                className: 'rounded-circle', src: `${authenticated ? profile : profilepic}`, alt: '',
              }}
              />
              <Media body>
                <span>{authenticated ? name : ""}</span>
              </Media>
            </Media>
            <UL attrUL={{ className: `simple-list profile-dropdown onhover-show-div` }}>
              {/* <LI><Link to={`${process.env.PUBLIC_URL}/sample-page`}><i><User /></i><span>{Account} </span></Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/sample-page`}><i><Mail /></i><span>{Inbox}</span></Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/sample-page`}><i><FileText /></i><span>{Taskboard}</span></Link></LI> */}
              <LI><Link to={`${process.env.PUBLIC_URL}/edit-profile`}><i><Settings /></i><span>Settings</span></Link></LI>
              <LI attrLI={{ onClick: Logout }}>
                <Link to={`${process.env.PUBLIC_URL}/logout`}>
                  <LogIn /><span>{LogOut}</span>
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div >
    </Fragment >
  );
};
export default HeaderContain;
