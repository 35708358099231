import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ToastContainer } from 'react-toastify';
import Footer from './Footer';
import Loader from './Loader';
import TapTop from './TapTop';
import Header from './Header';
import CheckContext from '../_helper/customizer';
import ConfigDB from '../Config/Theme-Config';
import AnimationThemeContext from '../_helper/AnimationTheme';

const Layout = () => {
  const { toggleIcon, setToggleIcon, setDefaultClass } = useContext(CheckContext);

  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);

  const animationTheme = localStorage.getItem('animation') || animation || ConfigDB.data.router_animation;

  useEffect(() => {
    const handleResize = () => {
      if ((window.innerWidth - 440) <= 575) {
        setToggleIcon(true);
      } else {
        setToggleIcon(false);
      }
      if (window.innerWidth <= 992) {
        setDefaultClass(true);
      } else {
        setDefaultClass(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setToggleIcon, setDefaultClass]);

  return (
    <>
      <Loader />
      <TapTop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className={`page-header ${toggleIcon ? 'close_icon' : ''}`}>
          <Header />
        </div>
        <div
          className="page-body"
          style={{
            paddingTop: '70px',
          }}
        >
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={100}
              classNames={animationTheme}
              unmountOnExit
            >
              <div style={{padding: '30px'}}>
                <Outlet />
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default Layout;
