import React, { Fragment, useEffect, useState } from 'react';

const Loader = (props) => {
  const [show, setShow] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    // Simulate progress
    const progressInterval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 10 : 100));
    }, 300);

    return () => {
      clearTimeout(timeout);
      clearInterval(progressInterval);
    };
  }, []);

  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (progress / 100) * circumference;

  return (
    <Fragment>
      {show && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '120px',
              height: '120px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <svg
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '120px',
                height: '120px',
                transform: 'rotate(-90deg)',
              }}
            >
              <circle
                cx="60"
                cy="60"
                r={radius}
                stroke="#e0e0e0"
                strokeWidth="10"
                fill="transparent"
              />
              <circle
                cx="60"
                cy="60"
                r={radius}
                stroke="#3498db"
                strokeWidth="10"
                fill="transparent"
                strokeDasharray={circumference}
                strokeDashoffset={progressOffset}
                style={{
                  transition: 'stroke-dashoffset 0.3s ease',
                }}
              />
            </svg>
            <span
              style={{
                position: 'relative',
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#3498db',
              }}
            >
              {progress}%
            </span>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Loader;
